import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import { Container, Button} from 'reactstrap';
import {resetPassword, getMessageToShow} from '../actions/index';
import InnovelFlashMessage from '../containers/common/innovel_flash_message';
import ResetResult from '../containers/login/reset_result';
import {LOGIN_ROOT} from '../config/api-config';
class UserReset extends Component {
    constructor(props) {
            super(props);
            var valueToSearch = props.location.search;
            if (valueToSearch!=="") {
                valueToSearch = valueToSearch.substr((valueToSearch.lastIndexOf("=")+1),(valueToSearch.length-1));
                this.state = {
                    email : '',
                    confirmationToken: valueToSearch,
                    password: '',
                    confirmPassword: '',
                    pwdErrorText: 'Password is required',
                    confErrorText: 'Please enter password to confirm',
                    pwdError: false,
                    confError: false
                }
            } else {
                this.state = {
                    email : ''
                }
            }
            this.handlePasswordChange = this.handlePasswordChange.bind(this);
            this.handleConfirmationChange = this.handleConfirmationChange.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);
    }

    handlePasswordChange(e) {
       const { name, value } = e.target;
       this.setState({ [name]: value }, ()=> {
            if (/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(this.state.password)){
                this.setState({ pwdError: false});
            } else {
                this.setState({ pwdError: true,
                    pwdErrorText: 'Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters'});
            }
            if (this.state.password!==this.state.confirmPassword){
                    this.setState({confError: true,
                    confErrorText: 'Passwords dont match!!'});
            } else {
                    this.setState({ confError: false});
            }
        });

     }
    handleConfirmationChange(e) {
       const { name, value } = e.target;
       this.setState({ [name]: value }, ()=> {
            if (this.state.password!==this.state.confirmPassword){
                this.setState({confError: true,
                confErrorText: 'Passwords dont match!!'});
            } else {
                this.setState({ confError: false});
            }
        });
     }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        const messageObj ={message :'' , alertType : 'reset'};
        this.props.getMessageToShow(messageObj);

    }
    handleSubmit(e){
        e.preventDefault();
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(this.state.email!=='' && ! this.state.email.match(mailformat))
        {
          const messageObj ={message :'Contact manager for password reset if needed' , alertType : 'error'};
          this.props.getMessageToShow(messageObj);
        }
        else if (this.state.email!=='') {
            this.props.resetPassword(this.state.email);
        } else if (this.state.confirmationToken!==undefined) {
            this.props.resetPassword(undefined, this.state.confirmationToken, this.state.password);
        }
    }
    navigateToLogin = () => {
        this.props.history.push('/login');
    }
    render() {
            const formStyle = {
                'margin': 0,
                'position': 'relative',
                'width': '50%',
                'left': '25%'
            };
            const { password, confirmPassword } = this.state;
            return (
            <Container>
                    <h1 align="center">Costco Logistics</h1>
                    <br/>
                {!this.state.confirmationToken &&
                    <div className="forgot-password">
                        <h3 align="center">Forgot password ?</h3>
                        {!this.props.response.reset &&  <form style={formStyle} className={["form-control", "form-control-sm"]} name="form" onSubmit={this.handleSubmit}>
                            <br/>
                            <div className="form-group">
                                <label className="float-left font-weight-bold" htmlFor="email">Please enter your registered username</label>
                                <input className="float-right form-control" type="text" name="email" onChange={this.handleChange} required />
                            </div>
                            <br/>
                            <div align="center" className="form-group" style={{marginTop : "10%"}}>
                                <button className="btn btn-primary">Send Email</button>
                            </div>
                        </form>}
                    </div>
                }
                {this.state.confirmationToken &&
                    <div className="set-password">
                        <h3 align="center">Set password</h3>
                        {!this.props.response.reset &&  <form style={formStyle} className={["form-control,form-control-sm"]} name="form" onSubmit={this.handleSubmit}>

                            <div className={"form-group" + (this.state.pwdError ? ' has-error' : '')}>
                                 <label className="float-left" htmlFor="password">New Password</label>
                                 <input className="float-right form-control" type="password" name="password" value={password} onChange={this.handlePasswordChange} required/>
                                 {this.state.pwdError &&
                                        <div className="text-danger"><br/>{this.state.pwdErrorText}<br/></div>
                                 }
                             </div>
                             <div className={"form-group" + (this.state.confError ? ' has-error' : '')}>
                                  <label className="float-left" htmlFor="confirmPassword">Confirm Password</label>
                                  <input className="float-right form-control" type="password"  name="confirmPassword" value={confirmPassword} onChange={this.handleConfirmationChange} required/>
                                  {this.state.confError &&
                                          <div className="text-danger"><br/>{this.state.confErrorText}<br/></div>
                                  }
                             </div>
                             <div align="center" className="form-group">
                                  <button className="btn btn-primary" style={{marginTop : "7%"}}>Confirm Password</button>
                             </div>
                         </form> }
                    </div>
                }
                <ResetResult/>
                <InnovelFlashMessage align="center"/>
                <div align="center">
                    <br/>
                    <Button onClick={this.navigateToLogin} color="link">Click here to go back to login page</Button><br/>
                </div>
            </Container>
            );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ resetPassword , getMessageToShow }, dispatch)
    }
}
function mapStateToProps(state) {
  return { response: state.reset };
}
export default connect(mapStateToProps,mapDispatchToProps)(UserReset);
