import React, { Component } from 'react';
import {BigSpinner} from './containers/common/big_spinner';
import styles from './styles.css.js'
import InnovelApp from './components/innovel_app';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {loading : true};
  }

  controlBigSpinner = (value) => {
    this.setState({loading:value});
  }

  scrollToBigSpinner = () => {
    if(this.messagesEnd !== undefined  && this.messagesEnd !== null){
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
  }

  render() {
      return (
        <div>
          {this.state.loading && (<div style={styles.bigSpinnerStyle}>
            <BigSpinner/>
            <div style={{ float:"left", clear: "both" }}
                 ref={(el) => { this.messagesEnd = el; }}>
            </div>
            </div>)}
          <div style={{zIndex: 1}}><InnovelApp controlBigSpinner={this.controlBigSpinner}/></div>
        </div>
      );
    }
  }


export default App;
