import React, { Component } from 'react';
import {Route,Switch} from 'react-router-dom';
import LoginPage from './login';
import UserReset from './user_reset';
import UnAuth from './unauth';
import styles from '../styles.css.js';
import NotFound from './notFound';

class View extends Component {
  render() {
    return (
      <div style={styles.flexWrapper}>
        <div style={styles.viewStyle}>
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/reset" component={UserReset} />
            <Route exact path="/unauth" component={UnAuth} />
            <Route exact path="/" component={LoginPage} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default View;
