import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Button} from 'reactstrap';
import {Redirect} from 'react-router-dom';
import { Alert } from 'reactstrap';
import {APP_CONTEXT} from '../config/api-config';
import { LOGIN_ROOT } from '../config/api-config';

class UnAuth extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }
  navigateToLogin = () => {
    window.location.href = LOGIN_ROOT;
  };
  // componentWillMount() {
  //         const messageObj ={message : 'Unauthorized User', alertType : 'error'};
  //         this.props.getMessageToShow(messageObj);
  // }

  render() {
    const formStyle = {
        'margin': 0,
        'position': 'relative',
        'width': '50%',
        'left': '25%'
    };
    return (
      <Container>
        <div align="center" style={{ paddingTop: '100px' }}>
          <Alert color="danger">Unauthorized User</Alert>
        </div>
        <div align="center">
          <Button
            onClick={this.navigateToLogin}
            color="link"
            style={{ paddingBottom: '100px' }}
          >
            Click here to go home
          </Button>
        </div>
      </Container>
    );
  }
  
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     ...bindActionCreators({getMessageToShow, }, dispatch)
//   }
// }
export default connect(null,null)(UnAuth);
