import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { startBigSpinner } from '../actions/index';

/* todo : marked for deprecation*/
class AuthRedirect extends Component {
  constructor(props) {
    super(props);
    var path = window.location.pathname;
    var urlParams = new URLSearchParams(window.location.search);
    var toRedirect; // null
    if (urlParams.get('redirect') !== null) {
      toRedirect = '/login?redirect=' + window.btoa(urlParams.get('redirect'));
    }
    this.state = {
      path: toRedirect
    };
  }

  componentWillReceiveProps(nextProps) {
    //This code has nothing to do with auth_redirect.
    //But it is the top most parent component that can control the big spinner.
    if (
      nextProps.bigSpinnerloading !== undefined &&
      nextProps.bigSpinnerloading !== null
    ) {
      this.props.controlBigSpinner(nextProps.bigSpinnerloading);
    }
  }

  componentDidMount() {
    this.props.startBigSpinner(false);
  /*do nothing if redirect querystring is not passed. */
  if(this.state.path!== undefined)
  {
    this.props.history.push(this.state.path)};
  }

  render() {
    return <div />;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ startBigSpinner }, dispatch)
  };
};

function mapStateToProps(state) {
  return { login: state.cred, bigSpinnerloading: state.loadingFlag };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthRedirect)
);
