import React, { Component } from 'react';
import styles from '../styles.css.js';
import '../../src/styles/footer.scss';

let var1 = Object.assign(styles.whiteColor, styles.boldFont);
let var2 = Object.assign(styles.tangerineColor, styles.boldFont);

class Footer extends Component {
  render() {
    return (

      <div
        id="footer"
        className="px-0 py-0 mx-0 my-0 mobile-hidden"
      >
        <div id="footerNav" className="sr-only" tabindex="-1"> </div>
        <footer className="footer1">
          <div className="container-fluid">
            <div className="">
              <div className="footer-wrapper footer-wrapper-l">
                <p className="text-t4 p-top">
                  <span>
                    <a href="https://logistics.costco.com/tracking#divTrackOrders" target='_blank' rel='noopener noreferrer' className='grey-900'>
                      Tracking
                    </a>
                  </span>
                </p>
                <p className="text-t4 p-top">
                  <span>
                    <a href="https://www.costco.com/" rel="noopener noreferrer" target='_blank' className='grey-900'>
                      Costco.com
                    </a>
                  </span>
                </p>
              </div>
              <hr className="bottom-hr" />

              <div className="footer-wrapper">
                <p className="text-t5 p-bottom">
                  <a href=" https://www.costco.com/terms-and-conditions-of-use.html" target='_blank' rel='noopener noreferrer' className='grey-800'>
                    Terms & Conditions
                  </a>
                </p>
                <p className="text-t5 p-bottom">
                  <a href="https://www.costco.com/privacy-policy.html" target='_blank' rel='noopener noreferrer' className='grey-800'>
                    Your Privacy Rights
                  </a>
                </p>
                <p className="text-t5 p-bottom">
                  <a href="https://www.costco.com/privacy-policy.html#cppa" target='_blank' rel='noopener noreferrer' className='grey-800'>
                   CA Notice
                  </a>
                </p>
                <p className="text-t5 p-bottom">
                <button id="ot-sdk-btn" class="ot-sdk-show-settings" style={{color:"#5f5f5f"}}>Cookie Settings</button>
                </p>
                <p className="text-t5 p-bottom">
                <a href="https://www.costco.com/DNSMIView" target='_blank' rel='noopener noreferrer'><img alt="California Consumer Privacy Act (CCPA) Opt-Out Icon" className='footer-img' src="https://mobilecontent.costco.com/live/resource/img/static-us-landing-pages/icon-privacy-choices.svg"/>
                </a>
                <a href="https://www.costco.com/DNSMIView " target='_blank' rel='noopener noreferrer' className='grey-800'>
                Your Privacy Choices
                </a>
                </p>
                {process.env.REACT_APP_DISPLAY_HEALTH_DATA == 'true' && 
                <p className="text-t5 p-bottom">
                  <a href="https://www.costco.com/consumer-health-data-privacy-policy.html" target='_blank' rel='noopener noreferrer' className='grey-800'>
                  Consumer Health Data
                  </a>
                </p>
                }

              </div>

              <div className="py-4 d-flex align-items-center">
                <div className="col-md-12 col-lg-12">
                  <p className="text-center text-bottom">
                    © 2020 - {new Date().getFullYear()} Costco Wholesale Corporation. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>

      </div>
    );
  }
}

export default Footer;
