import {LOGIN} from '../actions';

export default function (state = [], action) {
  switch (action.type) {
    case LOGIN:
      let to_return;
      if (action.error !== undefined) {
        to_return = {
          error : true,
          error_description : action.payload.response.data.error_description
        };
      } else {
              to_return = {
            error : false,
            payload : action.payload.data
          };
      }
      return to_return;
    default:
      return state;
  }
}
