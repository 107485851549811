import React, { Component } from 'react';
import styles from '../styles.css.js';
import CostcoLogo from '../images/costcoLogistics_s.png'

class CommonHeader extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{ color: '#FFFFFF', width: '100%', backgroundColor: '#FFFFFF', display: 'flex', justifyContent: 'center' }}>
        <img src={CostcoLogo} alt="Costco"
          style={{ color: '#FFFFFF', marginTop: '10px', height: '55px', backgroundColor: '#FFFFFF' }} />
      </div>
    )
  }
}

export default CommonHeader;
