const footerStyle = {
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: '100%',
  height: '25%',
  fontFamily: 'Corbel'
};
const footerbackcolor = { backgroundColor: '#18406F' };
const formStyle = {
  margin: 0,
  position: 'relative',
  width: '50%',
  left: '25%',
  fontFamily: 'Corbel'
};
const rememberStyle = { marginTop: '4px' };
const rememberTextStyle = { marginLeft: '4px', fontFamily: 'Corbel' };
const forgotStyle = { marginTop: '-5px', fontFamily: 'Corbel' };
const loginStyle = { margin: 'auto', width: '57%' };
const styleElement = { margin: '10px 10px', fontFamily: 'Corbel' };
const innovelSolLogo = {
  width: '70%',
  minWidth: '60px',
  margin: '5px 0px 5px 0',
  maxWidth: '80px'
};

const footermiddle = { height: '7px', backgroundColor: '#7AC143' };
const footerbottom = { height: '45%' };
const whiteColor = {
  color: '#FFFFFF',
  fontFamily: 'Corbel',
  fontSize: 12,
  paddingTop: '5px',
  paddingBottom: '10px'
};
const tangerineColor = { color: '#F15D22', fontSize: 15, paddingTop: '5px' };
const boldFont = { fontWeight: 'bold', fontFamily: 'Corbel' };
const headerStyle = {
  position: 'fixed',
  top: '0px',
  left: '0px',
  right: '0px',
  width: '100%',
  bottom: 'auto',
  zIndex: '999'
};
const viewStyle = {
  position: 'relative',
  top: '1px',
  left: '0px',
  right: '0px',
  marginTop: '1px',
  overflow: 'hidden'
};
const footerHeight = {
  left: '0px',
  right: '0px',
  bottom: '0px',
  overflow: 'hidden',
  'min-height': '100%',
  'min-width': '100%'
};
const onlyFontstyleElement = { fontFamily: 'Corbel' };
const textColorFont = { fontFamily: 'Corbel', color: 'black' };
const socialIcon = {
  height: 32,
  width: 32,
  margin: '5px 5px 5px 0'
};
const headertoppart = {
  color: '#009ddc',
  backgroundColor: '#FFFFF',
  fontWeight: 'bold',
  fontFamily: 'Muli',
  display: 'flex',
  justifyContent: 'space-between'
};
const headerbotompart = {};
const flexWrapper = {
  display: 'flex',
  minHeight: '65vh',
  flexDirection: 'column',
  justifyContent: 'space-between'
};
const bigSpinnerStyle = {
  position: 'fixed',
  marginLeft: 'auto',
  marginRight: 'auto',
  left: 0,
  right: 0,
  background: '#FFFFFF',
  height: '150%',
  zIndex: 2
};
const headerLogo = {
  width: '8vw',
  margin: '1%'
};
const blankItem = {
  width: '8vw',
  margin: '1%'
};
const headerText = {
  fontSize: '3.25vw',
  marginTop: '1%'
};

// color:'#FFFFFF', backgroundColor:'#18406F'

export default {
  flexWrapper,
  headerbotompart,
  headertoppart,
  socialIcon,
  textColorFont,
  onlyFontstyleElement,
  tangerineColor,
  headerStyle,
  viewStyle,
  footerHeight,
  boldFont,
  whiteColor,
  footerbottom,
  footermiddle,
  innovelSolLogo,
  footerbackcolor,
  footerStyle,
  formStyle,
  rememberStyle,
  rememberTextStyle,
  forgotStyle,
  loginStyle,
  styleElement,
  bigSpinnerStyle,
  headerLogo,
  blankItem,
  headerText
};
