import {RESET} from '../actions';

export default function (state = [], action) {
  switch (action.type) {
    case RESET:
      if (action.payload.status == 200)
        return {reset: true}
    else
        return {reset: false};
    default:
      return state;
  }
}
