import {apiRepo} from '../repository/apiRepo';
export const INIT = 'init';
export const LOGIN = 'login';
export const FLASH_MESSAGE = 'getMessageToShow';
export const RESET = 'reset';
export const START_BIG_SPINNER = 'start_big_spinner';

export function login(username, password, auth) {
    const request = apiRepo.serviceCall('post','/oauth/token',
    'grant_type=password&username='+username+'&password='+password,
    {'Content-Type' : 'application/x-www-form-urlencoded', 'Authorization': auth});
    return {
      type: LOGIN,
      payload: request
    };
}

export function resetPassword(email, token, password) {
    if (email!==undefined) {
        const request = apiRepo.serviceCall('get',`/credentials/reset?email=${email}`);
        return {
          type: RESET,
          payload: request
        };
    } else {
        const request = apiRepo.serviceCall('put',`/credentials/reset/${token}`,
                            {'serviceRequest':{
                                'password':password
                            }});
        return {
          type: RESET,
          payload: request
        };
    }
}

export function getMessageToShow(values) {
  return {
    type: FLASH_MESSAGE,
    payload: values
  };
}

export function startBigSpinner(values) {
  return {
    type: START_BIG_SPINNER,
    payload: values
  };
}
