import Spinner from 'react-spinner-material';
import React, { Component } from 'react';

export class BigSpinner extends Component {
  render() {
  return (
      <div align="center" style={{marginTop: '15%'}}>
        <Spinner size={120} spinnerColor={"#000080"} spinnerWidth={3} visible={true} />
      </div>
    );
  }
}
