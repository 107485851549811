import React, { Component } from 'react';
import AuthRedirect from './auth_redirect';
import CommonHeader from './common_header';
import View from './view';
import Footer from './footer';
import styles from '../styles.css.js';

class InnovelApp extends Component{

constructor(props) {
    super(props);
}

render(){
    return(
      <div>
        <AuthRedirect controlBigSpinner={this.props.controlBigSpinner}/>
        <div><CommonHeader/></div>
        <div><View/></div>
        <div><Footer/></div>
      </div>
    )
  }
}

export default InnovelApp;
