import { createStore, combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import LoginReducer from './reducer_login';
import FlashMessageReducer from './reducer_flash_message';
import ResetReducer from './reducer_reset';
import BigSpinnerReducer from './reducer_big_spinner';

const reducers = {
  cred: LoginReducer,
  flashMessage :FlashMessageReducer,
  reset: ResetReducer,
  loadingFlag: BigSpinnerReducer
};
const reducer = combineReducers(reducers);
const store = createStore(reducer);

export default reducer;
