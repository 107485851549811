import React, { Component } from 'react';
import { apiRepo } from '../repository/apiRepo';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Button } from 'reactstrap';
import { login, getMessageToShow, startBigSpinner } from '../actions/index';
import InnovelFlashMessage from '../containers/common/innovel_flash_message';
import styles from '../styles.css.js';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    const queryString = new URLSearchParams(window.location.search);
    this.state = {
      username: '',
      password: '',
      submitted: false,
      logged: false,
      redirectUrl: queryString.get('redirect')
    };

    const logout = queryString.get('logout');
    if (logout === 'true') {
      this.props.history.push('/login');
      localStorage.removeItem('token');
    }
    // localStorage.setItem("trackingNumber",this.getUrlParameter('order'));
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getUrlParameter = name => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(window.location.search);
    return results === null
      ? null
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  pageRedirect = initDetails => {
    const tokenFrmStore = localStorage.getItem('token');
    if (
      initDetails.crossOrigin !== undefined &&
      initDetails.crossOrigin !== null &&
      initDetails.crossOrigin
    ) {
      window.location.assign(
        'http://localhost:' +
          initDetails.port +
          '/' +
          initDetails.appContext +
          '/' +
          initDetails.app +
          '?token=' +
          tokenFrmStore +
          '&crossorigin=' +
          initDetails.crossOrigin
      );
    } else if (
      initDetails.crossOrigin !== undefined &&
      initDetails.crossOrigin !== null &&
      !initDetails.crossOrigin
    ) {
      window.location.assign(
        '/' + initDetails.appContext + '/' + initDetails.app
      );
    }
  };
  /*
        if (initDetails.transport && initDetails.order){
            window.location.assign(RFT_ROOT+"?token="+tokenFrmStore+"&crossorigin="+initDetails.crossOrigin);
          }else if (initDetails.transport && !initDetails.order){
            window.location.assign(RFT_ROOT+"?token="+tokenFrmStore+"&crossorigin="+initDetails.crossOrigin);
          }
          else if (!initDetails.transport && initDetails.order){
            window.location.assign(ORDER_ROOT+"?token="+tokenFrmStore+"&crossorigin="+initDetails.crossOrigin);
          }
    }else if (initDetails.crossOrigin !== undefined && initDetails.crossOrigin !== null && ! initDetails.crossOrigin){
      if (initDetails.transport && initDetails.order){
          window.location.assign(RFT_ROOT+"/");
        }else if (initDetails.transport && !initDetails.order){
          window.location.assign(RFT_ROOT+"/");
        }
        else if (!initDetails.transport && initDetails.order){
          window.location.assign(ORDER_ROOT+"/");
        }*/

  componentDidMount() {
    if (
      localStorage.getItem('username') === null &&
      this.refs.remember !== undefined
    ) {
      this.refs.remember.checked = false;
    } else {
      if (this.refs.remember !== undefined) {
        this.refs.remember.checked = true;
      }
      if (this.refs.username !== undefined) {
        this.refs.username.value = localStorage.getItem('username');
      }
      if (this.refs.password !== undefined) {
        this.refs.password.value = localStorage.getItem('password');
      }
      if (
        this.refs.username !== undefined &&
        this.refs.password !== undefined
      ) {
        this.setState({
          username: localStorage.getItem('username'),
          password: localStorage.getItem('password')
        });
      }
    }
  }

  callInit = () => {
    var redirectUrlParam =
      this.state.redirectUrl !== undefined && this.state.redirectUrl !== null
        ? '&redirect=' + window.btoa(this.state.redirectUrl)
        : '';

    apiRepo
      .serviceCall('get', '/v1/init?filter=login' + redirectUrlParam)
      .then(json => {
        if (
          json.status === 200 &&
          json.status !== undefined &&
          json.status !== null
        ) {
          const initDetails = json.data.serviceResponse;
          this.pageRedirect(initDetails);
        } else {
          const messageObj = {
            message: 'Error in Getting User Access. Please Contact Admin',
            alertType: 'error'
          };
          this.props.getMessageToShow(messageObj);
        }
      });
  };

  componentWillMount() {
    if (localStorage.getItem('token')) {
      if (
        localStorage.getItem('token') !== undefined &&
        localStorage.getItem('token') !== null
      ) {
        this.callInit();
      }
    }
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    const messageObj = { message: '', alertType: 'reset' };
    this.props.getMessageToShow(messageObj);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { username, password } = this.state;
    const { dispatch } = this.props;
    if (username && password) {
      this.props.startBigSpinner(true); // login call

      const auth = 'Basic ' + window.btoa('innovel:secret');
      //this.props.login(username, encodeURIComponent(password), auth).
      apiRepo
        .serviceCall(
          'post',
          '/oauth/token',
          'grant_type=password&username=' +
            username +
            '&password=' +
            encodeURIComponent(password),
          {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: auth
          }
        )
        .then(json => {
          if (
            json.status === 200 &&
            json.data.access_token !== undefined &&
            json.data.access_token !== null
          ) {
            localStorage.setItem('token', json.data.access_token);
            this.callInit();
          } else {
            this.props.startBigSpinner(false);
            const messageObj = {
              message: 'Un-Authorized user',
              alertType: 'error'
            };
            this.props.getMessageToShow(messageObj);
          }
        })
        .catch(Error => {
          this.props.startBigSpinner(false);
          if (
            Error.response !== undefined &&
            Error.response.status !== null &&
            Error.response.status === 400
          ) {
            const messageObj = {
              message: Error.response.data.error_description,
              alertType: 'error'
            };
            this.props.getMessageToShow(messageObj);
          } else if (Error.response === undefined) {
            const messageObj = {
              message:
                'There is a Technical issue with Costco Logistics. Please reach out to support team.',
              alertType: 'error'
            };
            this.props.getMessageToShow(messageObj);
          } else {
            const messageObj = {
              message:
                'There is a Technical issue with Costco Logistics. Please reach out to support team.',
              alertType: 'error'
            };
            this.props.getMessageToShow(messageObj);
          }
        });
    }
  }

  forgotPassword = e => {
    e.preventDefault();
    this.props.history.push('/reset');
  };

  rememberMe = e => {
    if (e.target.checked) {
      localStorage.setItem('username', this.state.username);
      localStorage.setItem('password', this.state.password);
    } else {
      localStorage.removeItem('username');
      localStorage.removeItem('password');
    }
  };

  render() {
    const { username, password, submitted } = this.state;
    return (
      <Container>
        <div style={styles.formStyle}>
                          {' '}
          <form name="form" onSubmit={this.handleSubmit}>
                                {' '}
            <div className={'' + (submitted && !username ? ' has-error' : '')}>
                                      {' '}
              <span>
                <label
                  className="float-left font-weight-bold"
                  htmlFor="username"
                >
                  Username
                </label>
                                        {' '}
                <input
                  className="float-right"
                  type="text"
                  className="form-control"
                  ref="username"
                  name="username"
                  value={username}
                  onChange={this.handleChange}
                  required
                />
                                        {' '}
                {submitted &&
                !username && (
                  <div className="text-danger">Username is required</div>
                )}
              </span>
                                  {' '}
            </div>
                                {' '}
            <div className={'' + (submitted && !password ? ' has-error' : '')}>
                                      {' '}
              <span>
                <label
                  className="float-left font-weight-bold"
                  htmlFor="password"
                >
                  Password
                </label>
                                        {' '}
                <input
                  className="float-right"
                  type="password"
                  className="form-control"
                  ref="password"
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                  required
                />
                                        {' '}
                {submitted &&
                !password && (
                  <div className="text-danger">Password is required</div>
                )}
              </span>
                                  {' '}
            </div>
            <div className="">
              <span className="float-left">
                <input
                  style={styles.rememberStyle}
                  className="float-left"
                  type="checkbox"
                  ref="remember"
                  onClick={this.rememberMe}
                />
                <label style={styles.rememberTextStyle} htmlFor="remember">
                  Remember me
                </label>
              </span>
              <span className="float-right">
                <Button
                  style={styles.forgotStyle}
                  type="reset"
                  className="float-right"
                  onClick={this.forgotPassword}
                  color="link"
                >
                  Forgot password ?
                </Button>
              </span>
            </div>
            <br />
                                {' '}
            <div className="">
                                      {' '}
              <button
                style={styles.loginStyle}
                className="btn btn-primary"
                onClick={this.handleSubmit}
              >
                Login
              </button>
                                  {' '}
            </div>
            <InnovelFlashMessage align="center" />
                            {' '}
          </form>
        </div>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      { login, getMessageToShow, startBigSpinner },
      dispatch
    )
  };
};

export default connect(null, mapDispatchToProps)(LoginPage);
