import React,{Component} from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Alert, Button } from 'reactstrap';
import {LOGIN_ROOT} from '../../config/api-config';

export class ResetResult extends Component {

  constructor(props) {
    super(props);
  }

  navigateToLogin = () => {
      this.props.history.push('/login');
  }

  render() {
          const errorStyle = {
              'margin': 0,
              'position': 'relative',
              'width': '50%',
              'left': '25%'
          };
    return (
      <div>
        {this.props.response.reset!==undefined &&
         (<div><div style={this.props.response.reset ? {} : { display: 'none' }} >
            <Alert color="success">
                User action successfully.
            </Alert>
        </div>
        <div style={!this.props.response.reset ? {} : { display: 'none' }} >
           <Alert style={errorStyle} color="danger">
               User action failed. Please try again later or contact admin for further details.
           </Alert>
       </div></div>)}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return { response: state.reset };
}

export default connect(mapStateToProps)(ResetResult);
